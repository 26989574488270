import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: '6idotcns',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skYh4V5yqu0BlvsYYYm2n3am7f7DO0QSwGCjkrcKWyhRvTLxJ2g4GY7XmGI16YjEE2SKEIUzMjeVBp5ByvU7qHmluwrl0qp5FNosZakcT0Km82Sj8wqwXydsSA9H7n1BqmlSY3at6lJaJi3HQz3LUYgD39A5pMoHJBR4OXZm9XfTKMZoyjMy',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);