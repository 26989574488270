import React from 'react'
import {Testimonial,About, Footer, Skills, Header, Work, Work_Info} from './container'
import { Route,Routes } from 'react-router-dom'
import { StateContext } from './context/StateContext'
import {Navbar} from './components'
import './App.scss'
const MainContent = ()=>{
  return(
    <>
      <Navbar render={true}/>
      <Header/>
      <About/>
      <Work/>
      <Skills/>
      <Testimonial/>
      <Footer/>
    </>
  )
}
const App = () => {
  return (
    <div className='app'>
      <StateContext>
      <Routes>
        <Route path='/' element={MainContent()}/>
      </Routes>
      <Routes>
        <Route path='/work-info/:title' element={<Work_Info/>}/>
      </Routes>
      </StateContext>
    </div>
  )
}

export default App