import React, { useState, useEffect } from 'react';
import { urlFor,client } from '../../client'
import { AppWrap } from '../../wrapper';
import { Navbar } from '../../components';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import {reactLocalStorage} from 'reactjs-localstorage';
import './Work_Info.scss'
const Work_Info = () => {
  const [data, setData] = useState([])
  let ImageArray = [];

  useEffect(() => {
    let localStorage =  reactLocalStorage.get('work_info')
    const query = '*[_type == "work_info"]';
    client.fetch(query).then((data) => {
      let filterdata = data.filter((work)=> work.title === localStorage)
      setData(filterdata);
    });
  }, []);

  const slider = (value)=>
    {
      value.map((item)=>{
        ImageArray.push({source:urlFor(item).url()})
      })
      return(
        <AwesomeSlider  
        animation="openAnimation"
        bullets={false}
        media={ImageArray}
        />
      );
    };

  return (
    <>
      <Navbar render={false}/>
      {data.map((item)=>(
        <div className='app__wrapper'>
          <div className='app__workinfo-header'>
            <div className='app__workinfo-title'>
              <h1 className='head-text'>{item.title}</h1>
            </div>
            <p  className='p-text2'>{item.short_description}</p>
            <div className='app__workinfo_button app__flex p-text item-active' onClick={()=>window.open(item.projectLink)}>🚀VISIT THE WEBSITE</div>
          </div>
          <div className='app__workinfo-slider'> 
          {slider(item.imagesGallery)}
          </div>
          <div className='app__workinfo-about'>
            <div className='app__workinfo-title'>
              <h1 className='head-text'>About This <span>Project</span></h1>
              <hr/>
            </div>
            {/* <p  className='p-text2'>{item.long_description}</p> */}
            <p  className='p-text2' dangerouslySetInnerHTML={{__html:(item.long_description)}} />
          </div>
          <div className='app__workinfo-tech'>
            <div className='app__workinfo-title'>
              <h1 className='head-text'>Technical Sheet</h1>
              <p className='p-text2'>Code technologies i got involved with while working on this project</p>
              <hr/>
            </div>
            <p  className='p-text2' dangerouslySetInnerHTML={{__html:(item.tags)}} />
          </div>
          <div className='app__workinfo-resources'>
            <div className='app__workinfo-title'>
              <h1 className='head-text'>Resources</h1>
              <hr/>
            </div>
            <p className='p-text2'>This project is online at <a href={item.projectLink} className='bold-text'>{item.projectLink}</a></p>
          </div>
        </div>
      ))}
      </>
  )
}

export default AppWrap(Work_Info,'work_info',"app__primarybg","false")