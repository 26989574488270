import React,{useState,useEffect} from 'react'
import './Work.scss'
import {AiFillEye,AiFillGithub} from 'react-icons/ai'
import { motion } from 'framer-motion'
import { urlFor,client } from '../../client'
import { AppWrap,MotionWrap } from '../../wrapper'
import { Link } from 'react-router-dom'
import {reactLocalStorage} from 'reactjs-localstorage';
const Work = () => {
  const [activeFilter, setActiveFilter] = useState(false)
  const [animateCard, setanimateCard] = useState({y:0,opacity:1})
  const [works, setWorks] = useState([])
  const [filterWork, setFilterWork] = useState([])
  const handleWorkFilter = (item)=>{
    setActiveFilter(item)
    setanimateCard([{y:100,opacity:0}])
    setTimeout(() => {
      setanimateCard([{y:0,opacity:1}])
      if(item === 'All'){
        let Data = works.filter((work)=> work.tags != "Empty")
        setFilterWork(Data)
      }else{
        
        let result = works.filter((work)=> work.tags.includes(item))
        if(result.length > 0){
          setFilterWork(result)
        }else{
          //no result
          result = works.filter((work)=> work.tags.includes("Empty"))
          setFilterWork(result)
        }
      }
    }, 500);
  }

  useEffect(() => {
    const query = '*[_type == "works"]'
    client.fetch(query)
      .then((data)=>{
        let Data = data.filter((work)=> work.tags != "Empty")
        setWorks(data);
        setFilterWork(Data)
      })
  }, [])
  
  const handleWork_Info = (value)=>{
    let localStorage = reactLocalStorage.get('work_info')
    if(localStorage.length === undefined || localStorage !== value){
      reactLocalStorage.set('work_info', value);
    }
  }

  return (
    <>
      <h2 className='head-text'>My Creative<span> Portfolio</span> Section</h2>
      <div className='app__work-filter'>
        {['All','UI/UX','Web App','Mobile App','React JS','MySQL','Animations','SASS','JavaScript','PHP','Front-End','Back-End','CSS','HTML'].map((item,index)=>(
          <div key={index} onClick={()=>handleWorkFilter(item)} className={`app__work-filter-item app__flex p-text ${activeFilter ===  item ? 'item-active' : ''}`}>{item}</div>
        ))}
      </div>
      <motion.div animate={animateCard} transition={{duration:0.5,delayChildren:0.5}} className="app__work-portfolio">
          {filterWork.map((work,index)=>(
              <div className='app__work-item app__flex' key={index}>
              <Link to={`/work-info/${work.title}`} onClick={()=>{handleWork_Info(work.title)}}>
                <div className='app__work-img app__flex'>
                  <img src={urlFor(work.imgUrl)} alt={work.name}/>
                  <motion.div whileHover={{opacity:[0,1]}} transition={{duration:0.25,ease:'easeInOut',staggerChildren:0.5}} className="app__work-hover app__flex">
                    <spam>
                      <motion.div whileInView={{scale:[0,1]}} whileHover={{scale:[1,0.9]}} transition={{duration:0.25}} className="app__flex">
                        <AiFillEye/>
                      </motion.div>
                    </spam>
                  </motion.div>
                </div>
                <div className='app__work-content app__flex'>
                  <h4 className='bold-text'>{work.title}</h4>
                  <p className='p-text' style={{marginTop:10}}>{work.description}</p>
                  <div className='app__work-tag app__flex'>
                    <p className='p-text'>{work.tags[0]}</p>
                  </div>
                </div>
                </Link>
              </div>
          ))}
      </motion.div>
    </>
  )
}

export default AppWrap(MotionWrap(Work,"app__works"),'work',"app__primarybg")