import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';
import php from '../assets/PHP.png'
import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';
import aspnet from '../assets/ASPNET.png'
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import test from '../assets/test.png'
import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';
import csharp from '../assets/Csharp.png'
import java from '../assets/Java.png'
import mysql from '../assets/MYSQL.png'
import r from '../assets/R.png'
export default {
  csharp,
  java,
  mysql,
  r,
  aspnet,
  email,
  mobile,
  test,
  cpp,
  css,
  git,
  html,
  javascript,
  mu5,
  node,
  react,
  sass,
  about01,
  about02,
  about03,
  about04,
  php,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  skype,
  spotify,
};