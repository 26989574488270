import React, { createContext, useContext, useState,useEffect } from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';

const Context = createContext();


export const StateContext = ({ children }) => {
    const [work_info, setWork_info] = useState()
    useEffect(() => {
        sync()
    }, [])

    async function sync(){
         let localStorage =  await reactLocalStorage.get('work_info')
        if(localStorage.length !== undefined ){
            setWork_info(localStorage)
            console.log(work_info)
        }
    }
    const handleWorkContent = (value)=>{
        let localStorage = reactLocalStorage.get('work_info')
        console.log(value)
        console.log(localStorage)
        if(localStorage.length === undefined || localStorage !== value){
            reactLocalStorage.set('work_info', value);
        }
    }   
    return (
        <Context.Provider
        value={{handleWorkContent,work_info}}>
        {children}
        </Context.Provider>
    )
}

export const useStateContext = () => useContext(Context);